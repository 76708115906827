import request from "api/request";

// 获取收录列表
export function confirmPermissionList(data) {
  return request({
    method: "post",
    url: "copyright/included/confirmPermissionsList",
    data,
  });
}
//版权认领申请
export function apply(data) {
  return request({
    url: "copyright/add/apply",
    method: "post",
    data,
  });
}
//版权纠错申请
export function error(data) {
  return request({
    url: "copyright/add/error",
    method: "post",
    data,
  });
}
